import React, { useEffect } from 'react';

const LOCALSTORAGE_SETTING_KEY = '__settingv3__';

let initialSettingState = {
  daily: {
    header: ['日付', '曜日', 'IMP', 'CT', 'CTR', 'CPC', 'コスト', 'CV', 'CVR', 'CPA'],
    useIndex: false,
    targets: [
      {
        name: '検索・ディスプレイ広告日別数値',
        startRow: 14,
        cols: 'C,D,E,F,G,H,I,J,K,L',
        appends: []
      }
    ]
  },
  keyword: {
    header: ['行番号', '媒体', 'キャンペーン', '広告グループ', 'キーワード', '検索方式', 'IMP', 'CT', 'CTR', 'CPC', 'コスト', '最上部表示率', '上部表示率', 'CV', 'CVR', 'CPA', 'm1CV', 'm1CVR', 'm1CPA', 'm2CV', 'm2CVR', 'm2CPA', 'm3CV', 'm3CVR', 'm3CPA', 'm4CV', 'm4CVR', 'm4CPA'],
    useIndex: true,
    targets: [
      {
        name: 'Google検索広告キーワード別数値',
        startRow: 9,
        cols: 'C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB',
        appends: [
          { value: 'Google', position: 0 }
        ]
      },
      {
        name: 'Yahoo検索広告キーワード別数値',
        startRow: 9,
        cols: 'C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB',
        appends: [
          { value: 'Yahoo', position: 0 }
        ]
      },
      {
        name: 'Microsoft検索広告キーワード別数値',
        startRow: 9,
        cols: 'C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB',
        appends: [
          { value: 'Microsoft', position: 0 }
        ]
      }
    ]
  },
  adgroup: {
    header: ['行番号', '媒体', 'キャンペーン', '広告グループ', '広告名', 'IMP', 'CT', 'CTR', 'CPC', 'コスト', 'CV', 'CVR', 'CPA', 'm1CV', 'm1CVR', 'm1CPA', 'm2CV', 'm2CVR', 'm2CPA', 'm3CV', 'm3CVR', 'm3CPA', 'm4CV', 'm4CVR', 'm4CPA'],
    useIndex: true,
    targets: [
      {
        name: 'Google検索広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN',
        appends: [
          { value: 'Googleサーチ', position: 0 }
        ]
      }, 
      {
        name: 'Yahoo検索広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN',
        appends: [
          { value: 'Yahooサーチ', position: 0 }
        ]
      }, 
      {
        name: 'Microsoft検索広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN',
        appends: [
          { value: 'Microsoftサーチ', position: 0 }
        ]
      },
      {
        name: 'Googleディスプレイ広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL',
        appends: [
          { value: 'Googleディスプレイ', position: 0 }
        ]
      }, 
      {
        name: 'Yahooディスプレイ広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL',
        appends: [
          { value: 'Yahooディスプレイ', position: 0 }
        ]
      }, 
      {
        name: 'Metaディスプレイ広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM',
        appends: [
          { value: 'Meta', position: 0 }
        ]
      }, 
      {
        name: 'LINEディスプレイ広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM',
        appends: [
          { value: 'LINE', position: 0 }
        ]
      }, 
      {
        name: 'Microsoftディスプレイ広告広告別数値(広告グループ別)',
        startRow: 9,
        cols: 'C,D,E,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL',
        appends: [
          { value: 'Microsoftディスプレイ', position: 0 }
        ]
      },
      {
        name: 'Googleディスプレイ広告キャンペーン別数値',
        startRow: 9,
        targetCol: 'D',
        targetValue: ['P-MAX'],
        cols: 'D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X',
        disableEdit: true,
        appends: [
          { value: '', position: 1 },
          { value: '', position: 2 },
          { value: 'Googleディスプレイ', position: 0 },
        ]
      },
    ]
  }
}

try {
  const loaded = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_SETTING_KEY) || "");
  initialSettingState = loaded;
} catch (e) {
  ;
}

const initialState = {
  logs: [],
  table: [],
  workbook: null,
  setting: initialSettingState,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "show-table": {
      return {
        ...state,
        table: action.payload
      }
    }
    case "hide-table": {
      return {
        ...state,
        table: []
      }
    }
    case "register-workbook": {
      return {
        ...state,
        workbook: action.payload
      }
    }
    case "push-log": {
      return {
        ...state,
        logs: state.logs.concat([action.payload])
      }
    }
    case "add-target": {
      const type = action.payload;
      const target = {
        name: 'シート名を入力してください',
        startRow: 1,
        cols: 'A,B,C',
        appends: []
      }
      return {
        ...state,
        setting: {
          ...state.setting,
          [type]: {
            ...state.setting[type],
            targets: state.setting[type].targets.concat([target])
          }
        }
      }
    }
    case "update-target": {
      const { type, index, key, value } = action.payload;
      return {
        ...state,
        setting: {
          ...state.setting,
          [type]: {
            ...state.setting[type],
            targets: state.setting[type].targets.map((target, i) => {
              if (i === index) {
                return {
                  ...target,
                  [key]: value
                }
              } else {
                return target;
              }
            })
          }
        }

      }
    }
    case "remove-target": {
      const { type, index } = action.payload;
      return {
        ...state,
        setting: {
          ...state.setting,
          [type]: {
            ...state.setting[type],
            targets: state.setting[type].targets.filter((_, i) => i !== index)
          }
        }
      }
    }
    default: return state;
  }
}

export const StateContext = React.createContext({});

export const StateProvider = ({ children }) => {

  const [state, originalDispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_SETTING_KEY, JSON.stringify(state.setting));
  }, [state.setting])

  const dispatch = React.useCallback(async (action) => {
    switch(action.type) {
      case "parse": {
        break;
      }
      default: originalDispatch(action);
    }
  }, [state]);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      { children }
    </StateContext.Provider>
  )
}
