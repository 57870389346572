import React from "react";
import style from './Column.module.css'
import useExtract from "./useExtract";
import { StateContext } from "./StateProvider";

export default function Column ({ title, type }) {

  const { state: { workbook, setting }, dispatch } = React.useContext(StateContext);
  const { targets, useIndex, header } = setting[type];
  const { extracted, tsv } = useExtract(workbook, targets, header, useIndex, title);

  const handleClickCopy = React.useCallback(() => {
    if (tsv === '') return;
    navigator.clipboard.writeText(tsv)
      .then(() => {
        dispatch({ type: 'push-log', payload: { type: 'success', message: `${title}のデータをコピーしました。` }})
      }, 
      () => {
        dispatch({ type: 'push-log', payload: { type: 'error', message: `${title}のデータをコピーすることに失敗しました。` }})
      });
  }, [tsv]);

  const handleClickShowTable = () => {
    dispatch({ type: 'show-table', payload: extracted });
  }

  return (
    <div className={style.root}>
      <div className={style.header}>
        <h3>{title}</h3>
        <button 
          disabled={tsv === ''}
          onClick={handleClickCopy}
        >データをコピー</button>
        <button 
          disabled={extracted.length === 0}
          onClick={handleClickShowTable}
        >データを確認</button>
      </div>

      <div className={style.setting} data-disabled={!!workbook}>
        {targets.map((target, index) => (
          <div key={index} className={style.target}>
            {target.disableEdit ? (
              <p>
                こちらの設定は固定されているため編集不可です。<br/>
                対象シート名: {target.name}<br/>
                判定対象列: {target.targetCol}<br/>
                対象文字列: {target.targetValue}
              </p>
            ) : (
              <>
            <label>
              <span>対象シート名</span>
              <input
                type="text"
                defaultValue={target.name}
                onChange={(event) => {
                  dispatch({ 
                    type: 'update-target',
                    payload: { type, index, key: 'name', value: event.target.value || '' } 
                  });
                }}
              />
            </label>
            <label>
              <span>抽出開始行</span>
              <input
                type="number"
                min="1"
                step="1"
                defaultValue={target.startRow}
                onChange={(event) => {
                  dispatch({ 
                    type: 'update-target',
                    payload: { type, index, key: 'startRow', value: Number(event.target.value) || 1 } 
                  });
                }}
              />
            </label>
            <label>
              <span>抽出対象列</span>
              <input
                type="text"
                defaultValue={target.cols}
                onChange={(event) => {
                  dispatch({ 
                    type: 'update-target',
                    payload: { type, index, key: 'cols', value: event.target.value || '' } 
                  });
                }}
              />
            </label>
            <label>
              <span>媒体名</span>
              <input
                type="text"
                defaultValue={target.appends[0] ? target.appends[0].value : ''}
                onChange={(event) => {
                  const value = event.target.value === '' ? [] : [{ position: 0, value: event.target.value }]
                  dispatch({ 
                    type: 'update-target',
                    payload: { type, index, key: 'appends', value } 
                  });
                }}
              />
            </label>
            <button onClick={() => {
              dispatch({ type: 'remove-target', payload: { type, index } });
            }}>削除</button>
              </>

            )}
          </div>
        ))}
        <div className={style.add} onClick={() => {
          dispatch({ type: 'add-target', payload: type });
        }}>
          <p><strong>+</strong><br/>対象シートを追加する</p>
        </div>
      </div>
    </div>
  )
}